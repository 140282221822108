import React from "react"
import { Link } from "gatsby"
import Image from "../components/image"
import Layout from "../components/layout"
// import SEO from "../components/seo"
import "../assets/sass/landscaping.sass"

const ServiceArea = () => (

<div className="info-page service-area">

    <div className="info-page-intro">
        <h1>Service Area</h1>
        <p>Aurora, Naperville, Plainfield, Joliet,</p>
    </div>
    
    <Image filename="backyard-landscape.jpg" />

    <div className="discription">

        <p>We provide various services during Summer, Fall, and Winter.</p>
        <p>We do work in Aurora, Naperville, Plainfield, Joliet, and near the Chicagoland area. </p>

    </div>

</div>

)

export default ServiceArea